/* Import project bootstrap / theme variables */

@import "project-variables";
@import "iconfont";

/* Load theme */

@import "theme";
@media (max-width: $screen-xs) {
    div#slogan p,
    div#slogan-sales p {
        margin-top: 150px;
    }
}


/* Medium devices (desktops, 992px and up) */

@media (min-width: $screen-md) {
    div#slogan p {
        width: 72%;
        margin-top: 100px;
    }
}


/* Large devices (large desktops, 1200px and up) */

@media (min-width: $screen-lg) {
    div#slogan p {
        width: auto;
    }
}

#pros {
    background: rgba(0,24,91,0.9);
}