$brand-primary:             rgb(0,24,91);
$brand-primary-light:       lighten($brand-primary, 80%);
$brand-primary-dark:        darken($brand-primary, 5%);
$brand-secondary:           rgb(85,166,38);
$brand-secondary-light:     lighten($brand-secondary, 15%);
$brand-secondary-dark:      darken($brand-secondary, 10%);
$brand-danger:              rgb(130,102,30);
$gray-base:                 #444;
$gray-light:                #ddd;
$gray-lighter:              #eee;
$gray-lightest:             #f7f7f7;
$component-active-color:    #fff;
$component-active-bg:       $brand-primary;